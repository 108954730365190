<template>
  <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/social.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Your Social Channels</p>

          </div>

        </div>
      </div>
      <div class="flex items-center justify-start mb-5 ml-[48px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4  d-flex">
          <div class="flex items-center gap-1">
            <p class="text-xs md:text-[18px] text-gray-600">
              Connect your social channels for Fly to post on your behalf
            </p>
          </div>
        </div>
      </div>
      <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl">
        <div class="flex flex-col">
          <div class="flex flex-col mt-6">
            <div class=" bg-white rounded-3xl">
              <please-wait v-if="loading" class="my-5"></please-wait>
              <template v-else>
                <div class="px-4 py-6 mt-4 bg-slate-100 rounded-xl">
                  <div class="font-medium text-white">
                    <div class="flex justify-between">
                      <!-- <div class="text-lg text-black">Connected</div> -->
                    </div>
                    <div class="">
                      <connected-app-list-item v-for="(item, i) in socialAppLists" @onDisconnect="fetchConnectedApps()"
                        :item="item" :profile="profile" :isLast="socialAppLists.length - 1 == i" :key="i" :allConnectedProfiles="allConnectedProfiles"/>
                    </div>
                  </div>
                </div>

                <!-- <div class="px-4 py-6 mt-4 bg-slate-100 rounded-xl">
                  <div class="font-medium text-white">
                    <div class="flex justify-between">
                      <div class="text-lg text-black">Connect now</div>
                    </div>
                    <div class="">
                      <connect-now-app-list-item v-for="(item, i) in socialAppLists"
                        @onDisconnect="fetchConnectedApps()" :item="item" :profile="profile"
                        :isLast="socialAppLists.length - 1 == i" :key="i" />
                    </div>
                  </div>
                </div> -->

                <!-- Coming Soon Section with Special Background -->
                <!-- <div class="px-4 py-6 mt-4 bg-slate-100 rounded-xl">
              <div class="font-medium text-black">
                <div class="flex justify-between">
                  <div class="text-lg text-black">Coming soon</div>
               
                </div>
                <div class="flex flex-wrap gap-4">
                  <connecte-app-list-item
                    v-for="(item, i) in socialAppLists"
                    @onDisconnect="fetchConnectedApps()"
                    :item="item"
                    :profile="profile"
                    :isLast="socialAppLists.length - 1 == i"
                    :key="i"
                  />
                </div>
              </div>
            </div> -->
              </template>

              <add-social-profile-dialog ref="dialog" @onNewProfile="fetchSocialProfiles()" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">{{ completedSteps }} of {{ totalSteps }} steps completed</div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{ item.name }}</router-link>
          </label>
     <router-link :to="item.route">
    <v-icon color="white cursor-pointer">mdi-chevron-right</v-icon>
  </router-link>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with a member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">Dismiss</button>
      </div>
    </div>
  </div>
</template>

<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth, colUsers, platforms } from "@/utils/firebase.utils.js";
import { db } from "@/utils/firebase.utils";
import {
  collection,
  doc,
  onSnapshot,
  query,
  getDocs,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import ConnectedAppListItem from "@/components/connectedApps/ConnectedAppListItem.vue";
import ConnectNowAppListItem from "@/components/connectedApps/ConnectNowAppListItem.vue";
import ConnecteAppListItem from "@/components/connectedApps/ConnecteAppListItem.vue";
import AddSocialProfileDialog from "@/components/connectedApps/AddSocialProfileDialog.vue";

export default {
  components: {
    PleaseWait,
    ConnectedAppListItem,
    ConnectNowAppListItem,
    ConnecteAppListItem,
    AddSocialProfileDialog,
  },
  data() {
    return {
      loading: true,
      unsubscribeConnection: null,
      connections: {},
      profiles: [],
      allConnectedProfiles:[],
      profile: "personal",
      socialAppLists: [],
      isOnboardingVisible: true,
      dismissed: true,
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
    };
  },
  mixins: [connectedAppsMixins],
  computed: {
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
  },
  watch: {
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
    currentUID() {
      this.fetchConnectedApps();
    },
    WID() {
      this.fetchConnectedApps();
    },
  },
  methods: {
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed = data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },
    async fetchConnectedApps() {
  const vm = this;
  if (!vm.currentUID) return;
  try {
    vm.loading = true;
    const allPlatforms = await vm.getAllPlatforms();
    let userCollections = {};

    let q = query(collection(doc(colUsers, vm.currentUID, "fly_workspaces", vm.WID), "connected-apps"));

    vm.unsubscribeConnection = onSnapshot(q, (snap) => {
      this.allConnectedProfiles = [];
      snap.docs.forEach((item) => {
        vm.connections[item.id] = { ...item.data(), id: item.id };
        userCollections[item.id] = { ...item.data(), id: item.id };
        let childrenArray = item.data().pages || item.data().profiles || item.data().boards || item.data()["sub-reddits"] || item.data().blogs || (Array.isArray(item.data()?.accounts) && item.data().accounts[0]?.locations) || item.data().users || [];
        if(item.id=="linkedin"){
          childrenArray = childrenArray.map((d)=>{
            return {
            ...d,
            user_id:item.data().user_id
            }
          })
        }
        this.allConnectedProfiles.push({
            name: item.id,
            children: childrenArray
        });
        // console.log(item.data(),"thise are connected",item.id)
      });

      // Create a list of social apps with their connection data
      vm.socialAppLists = allPlatforms.map((item) => {
        let connectionId = item.alias;
        if (item && item.type) {
          if (item.type == "google_business_profile") {
            connectionId = item.type;
          }
        }
        const connectionData = userCollections[connectionId];
        return { ...item, connection: connectionData };
      });

      // Sort socialAppLists to display connected apps at the top
      vm.socialAppLists.sort((a, b) => {
        if (a.connection && !b.connection) return -1;
        if (!a.connection && b.connection) return 1;
        return 0;
      });

      // Check if any social handles are connected
      const anyConnected = Object.keys(vm.connections).length > 0;
      if (anyConnected) {
        // Mark the onboarding step as completed
        const connectStep = vm.onboardingSteps.find(step => step.key === "is_connected");
        if (connectStep && !connectStep.completed) {
          connectStep.completed = true;
          vm.updateOnboardingStepsInFirestore(); // Update the onboarding steps in Firestore
        }
      }
    });
    vm.loading = false;
  } catch (error) {
    vm.loading = false;
    vm.$alert.show(error.message);
  }
},
    async fetchSocialProfiles() {
      let profileQuery = query(
        collection(doc(colUsers, this.uid), "social-profiles")
      );
      this.profiles = (await getDocs(profileQuery)).docs.map((i) => {
        return { ...i.data(), id: i.id };
      });
      let personalProfile = this.profiles.find((i) => i.id == "personal");
      if (personalProfile == null) {
        this.profiles.unshift({ name: "Personal", id: "personal" });
      }
      return this.profiles;
    },
    async getAllPlatforms() {
      const platformsRef = await getDocs(platforms);

      let allPlatforms = [];

      platformsRef.forEach((doc) => {
        allPlatforms.push(doc.data());
      });

      allPlatforms.sort((a, b) => a.display_priority - b.display_priority);

      return allPlatforms;
    },
  },
  beforeDestroy() {
    if (this.unsubscribeConnection) {
      this.unsubscribeConnection();
    }
  },
  beforeMount() {
    this.fetchConnectedApps();
    // this.getAllPlatforms();
  },
  async mounted() {
    if (auth.currentUser.uid !== this.currentUID) {
      this.$router.push("/calendar")
    }
    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-start justify-center"},[_c('div',{staticClass:"w-full h-auto bg-white rounded-3xl"},[_c('div',{staticClass:"flex flex-col items-center px-4 py-5 md:pl-0 lg:px-5"},[_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('div',{staticClass:"w-full text-sm font-medium text-neutral-900 cursor-pointer",on:{"click":function($event){!_vm.is_cross_post ? _vm.openPostDetail() : null}}},[_vm._v(" "+_vm._s(_vm.post.name)+" ")]),(!_vm.is_cross_post)?_c('div',{staticClass:"flex gap-[14px] items-center mr-4"},[(
                _vm.checkUID &&
                (!_vm.post.post_status ||
                  (_vm.post.post_status && _vm.post.post_status !== 'deleted'))
              )?_c('div',{staticClass:"bg-[#F2F6FA] rounded-md p-[7px] cursor-pointer",on:{"click":function($event){return _vm.deletePost(_vm.post)}}},[_c('img',{staticClass:"w-5",attrs:{"src":require("../../assets/img/post-icons/deleteicon.svg"),"alt":"delete"}})]):_vm._e(),_c('div',{staticClass:"bg-[#F2F6FA] cursor-pointer rounded-md p-[7px]",on:{"click":function($event){return _vm.copyLink()}}},[_c('img',{staticClass:"w-5",attrs:{"src":require("../../assets/img/post-icons/copyicon.svg"),"alt":"copy"}})]),(_vm.showPreviewIcon)?_c('a',{staticClass:"bg-[#F2F6FA] cursor-pointer rounded-md px-2 py-[0.15rem]",on:{"click":function($event){return _vm.openPostDetail()}}},[_c('v-icon',{staticClass:"eye-icon"},[_vm._v(" mdi-eye ")])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex flex-col md:flex-row w-full"},[(_vm.carousels.length)?_c('div',{staticClass:"w-full md:w-[100px]"},[_c('v-carousel',{staticClass:"mt-1 rounded-lg carousel-desktop-width",attrs:{"hide-delimiters":"","show-arrows":_vm.carousels.length > 1,"height":_vm.isMobile ? '310px' : '100px'},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.carousels),function(item,index){return _c('v-carousel-item',{key:index},[_c('div',{staticClass:"h-full d-flex align-center"},[(item.type == 'image')?_c('v-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.src,"height":_vm.isMobile ? '200px' : '100px'}}):_vm._e(),(item.type == 'video')?_c('video-auto-play',{staticStyle:{"height":"200px","object-fit":"cover"},attrs:{"autoplay":false,"videoSrc":item.src}}):_vm._e()],1)])}),1)],1):_vm._e(),_c('div',{staticClass:"md:flex-1 text-xs font-normal text-justify text-slate-500 mt-4 md:mt-0 md:ml-4",class:{
              'w-full': _vm.carousels.length === 0,
              'md:w-1/2': _vm.carousels.length > 0,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.showFullText ? _vm.post.text : _vm.shortText)+" ")]),(_vm.post.text.length > 100)?_c('button',{staticClass:"text-xs text-blue-600",on:{"click":function($event){_vm.showFullText = !_vm.showFullText}}},[_vm._v(" "+_vm._s(_vm.showFullText ? "Show Less" : "Show More")+" ")]):_vm._e(),_c('div',{staticClass:"text-slate-500 mt-2"},[_vm._v(" "+_vm._s(_vm.formatHashtags(_vm.post.hashtags))+" ")]),_c('div',{staticClass:"flex flex-wrap items-center justify-between w-full mt-4"},[_c('div',{staticClass:"flex gap-[3px]"},_vm._l((_vm.post.social_media),function(socialMedia,index){return _c('v-avatar',{key:index,attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":socialMedia.logo_url,"alt":socialMedia.name}})],1)}),1),_c('div',{staticClass:"mt-2 ml-1 text-xs font-normal text-slate-500"},[(!_vm.post.scheduled_at && _vm.post.created_at)?_c('p',[_c('span',{staticClass:"tooltip",attrs:{"data-tooltip":"Sent"}}),_vm._v(" "+_vm._s(_vm.formatDate(_vm.post.created_at.seconds * 1000))+" ")]):_vm._e(),(_vm.post.when_to_post != 'immediate')?_c('p',[_c('span',{staticClass:"tooltip",attrs:{"data-tooltip":"Schedule"}}),_vm._v(" "+_vm._s(_vm.post.scheduled_at.seconds ? _vm.formatDate(_vm.post.scheduled_at.seconds * 1000) : _vm.formatDate(_vm.post.scheduled_at))+" ")]):_vm._e()])]),_c('div',{staticClass:"status-container mr-7"},[(_vm.post.post_status === 'deleted')?_c('span',{staticClass:"status-deleted text-xs bg-slate-100 rounded-3xl p-3"},[_vm._v("Deleted")]):(_vm.post.post_status === 'draft')?_c('span',{staticClass:"status-draft text-xs bg-slate-100 rounded-3xl p-3"},[_vm._v("Draft")]):(
                  _vm.post.when_to_post === 'schedule' && !_vm.isPostLive(_vm.post)
                )?_c('span',{staticClass:"status-scheduled text-xs bg-slate-100 rounded-3xl p-3"},[_vm._v("Scheduled")]):(
                  _vm.post.when_to_post === 'immediate' ||
                  (_vm.post.scheduled_at && _vm.isPostLive(_vm.post))
                )?_c('span',{staticClass:"status-published text-xs bg-slate-100 rounded-3xl p-3"},[_vm._v("Published")]):_vm._e()])])]),(_vm.postedLocationCount)?_c('div',{staticClass:"text-xs font-medium text-slate-600 mb-2 w-full"},[_vm._v(" Posted to Locations: "+_vm._s(_vm.postedLocationCount)+" | Break-up below: "),_c('table',{staticClass:"platform-status-table mt-2 w-full"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left px-2 py-1"},[_vm._v("Platform")]),_c('th',{staticClass:"text-center px-2 py-1"},[_vm._v(" Published ("+_vm._s(_vm.getTotalStatusCount("published"))+") ")]),_c('th',{staticClass:"text-center px-2 py-1"},[_vm._v(" Failed ("+_vm._s(_vm.getTotalStatusCount("failed"))+") ")]),_c('th',{staticClass:"text-center px-2 py-1"},[_vm._v(" Pending ("+_vm._s(_vm.getTotalStatusCount("pending"))+") ")])])]),_c('tbody',_vm._l((_vm.platformStatusMap),function(stats,platform){return _c('tr',{key:platform},[_c('td',{staticClass:"px-2 py-1 capitalize"},[_vm._v(" "+_vm._s(_vm.formatPlatformName(platform))+" ")]),_c('td',{staticClass:"text-center px-2 py-1"},[_c('span',{class:{
                      'text-green-600':
                        _vm.getStatusCount(platform, 'published') > 0,
                    }},[_vm._v(" "+_vm._s(_vm.getStatusCount(platform, "published"))+" ")])]),_c('td',{staticClass:"text-center px-2 py-1"},[_c('span',{class:{
                      'text-red-600': _vm.getStatusCount(platform, 'failed') > 0,
                    }},[_vm._v(" "+_vm._s(_vm.getStatusCount(platform, "failed"))+" ")])]),_c('td',{staticClass:"text-center px-2 py-1"},[(_vm.getStatusCount(platform, 'pending') > 0)?_c('div',{staticClass:"pending-loader-container",class:{
                      'text-blue-600':
                        _vm.getStatusCount(platform, 'pending') > 0,
                    }},[_vm._v(" "+_vm._s(_vm.getStatusCount(platform, "pending"))+" "),_c('div',{staticClass:"pending-loader"})]):_c('span',{class:{
                      'text-blue-600':
                        _vm.getStatusCount(platform, 'pending') > 0,
                    }},[_vm._v(" "+_vm._s(_vm.getStatusCount(platform, "pending"))+" ")])])])}),0)])]):_vm._e(),_c('div',{staticClass:"flex items-center justify-center mt-2 w-full"},[_c('div',{staticClass:"w-[350px] flex gap-[10px]"},[(!_vm.is_cross_post)?_c('button',{staticClass:"w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full",on:{"click":function($event){_vm.post.post_status === 'draft'
                  ? _vm.editDraft(_vm.post.id)
                  : _vm.post.when_to_post !== 'immediate' && !_vm.isPostLive(_vm.post)
                  ? _vm.editPost(_vm.post.id)
                  : _vm.repost(_vm.post.id)}}},[_c('img',{attrs:{"src":require("../../assets/img/post-icons/repost.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.post.post_status === "draft" ? "Edit" : _vm.post.when_to_post !== "immediate" && !_vm.isPostLive(_vm.post) ? "Edit" : "Repost")+" ")]):_vm._e(),(
                !_vm.post.post_status ||
                (_vm.post.post_status && _vm.post.post_status !== 'deleted')
              )?_c('button',{staticClass:"w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full",on:{"click":function($event){_vm.showOutput = !_vm.showOutput}}},[_c('img',{attrs:{"src":require("../../assets/img/post-icons/graph.svg"),"alt":""}}),_vm._v(" "+_vm._s("Stats")+" "),(_vm.showOutput)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()])])]),(_vm.showOutput)?_c('div',{staticClass:"mt-2 w-full"},[_c('post-output',{attrs:{"scheduled_at":_vm.post.scheduled_at
              ? _vm.post.scheduled_at.seconds
                ? _vm.formatDate(_vm.post.scheduled_at.seconds * 1000)
                : _vm.post.scheduled_at
              : '',"postUID":_vm.post.uid,"postId":_vm.post.id,"postwid":_vm.post.wid,"post":_vm.post}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { auth, googleProjectId, googleRegion } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { functions, isLocalHost } from "@/utils/firebase.utils";
import axios from "axios";
import { updateDoc, doc } from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";

export default {
  methods: {
    async initConnection(name, profile, litewid) {
      try {
        this.$mixpanel.track("Connect App Now Clicked");
        this.isLoading = true;

        // Determine UID and WID based on route parameters
        const uid = this.$route.name === 'store-invitation-accepted' ? this.$route.query.uid : litewid ? this.uid : this.currentUID;
        const wid = this.$route.name === 'store-invitation-accepted' ? this.$route.query.wid : litewid || this.WID;

        const body = JSON.stringify({ uid, wid, profile });
        const request_url = `https://${googleRegion}-${googleProjectId}.cloudfunctions.net/oauth/${name}/request_token.html`;
        console.log("request_url:", request_url);

        const response = await axios.post(request_url, body, {
          headers: {
            "Content-Type": "text/plain",
          },
        });

        this.isLoading = false;
        this.dialog = false;
        console.log("Response:", response.data);

        const url = response.data.authLink;

        const data = {
          last_posted_at: new Date(),
        };

        await updateDoc(doc(colUsers, uid), data);
        if (profile === "send_connection_request") {
          console.log(profile, url, "Returning URL for connection request");
          return url;
        }
        this.openLoginDialog(url);

      } catch (error) {
        this.isLoading = false;
        if (error.response) {
          console.error("Error Response:", error);
          this.$alert.show("Error Response: " + error.response.data.message);
        } else {
          console.error("Error:", error);
          this.$alert.show("An error occurred. Please try again.");
        }
      }
    },

    openLoginDialog(url) {
      let w = 500;
      let h = 600;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      const win = window.open(
        url,
        "_blank",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}`
      );
      try {
        win.focus();
        win.moveBy(left, top);
      } catch (e) {
        this.$alert.show(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    },

    async disconnectConnection(name, profile, litewid) {
      const displayName = name === 'google_business_profile' ? 'Google Business Profile' : name.charAt(0).toUpperCase() + name.slice(1);
      this.$loader.show(`Disconnecting ${displayName}`);
      this.isDisconnectLoading = true;

      const uid = this.$route.name === 'store-invitation-accepted' ? this.$route.query.uid : litewid ? this.uid : this.currentUID;
      const wid = this.$route.name === 'store-invitation-accepted' ? this.$route.query.wid : litewid || this.WID;

      if (this.item?.type === 'marketplace') {
        const payload = {
          uid,
          wid,
          action: 'disconnect',
        };

        try {
          const functionName = this.item.alias === 'olx' ? 'olxRoutes' : (this.item.alias === 'quickr' ? 'quickrRoutes' : null);
          if (!functionName) throw new Error("Invalid item alias");

          const deleteUser = httpsCallable(functions, functionName);
          const disconnectResponse = await deleteUser(payload);

          setTimeout(() => {
            this.$alert.show("Response: " + disconnectResponse.data.message);
            this.$emit("onDisconnect");
            this.$loader.hide();
            this.isDisconnectLoading = false;
          }, 2000);

        } catch (error) {
          console.error('Disconnect error:', error);
          this.isDisconnectLoading = false;
          this.$alert.show("An error occurred: " + error.message);
        }

      } else {
        const body = `{"uid": "${uid}", "wid": "${wid}"}`;
        let request_url = `https://${googleRegion}-${googleProjectId}.cloudfunctions.net/oauth/${name}/revoke_token.html`;

        axios.post(request_url, body, {
            headers: { "Content-Type": "text/plain" },
          })
          .then((response) => {
            this.isDisconnectLoading = false;
            this.$alert.show("Response: " + response.data.message);
            this.$emit("onDisconnect");
            this.$loader.hide();
          })
          .catch((error) => {
            this.isDisconnectLoading = false;
            if (error.response) {
              console.error("Error Response:", error);
              this.$alert.show("Error Response: " + error.response.data.message);
            } else {
              console.error("Error:", error);
              this.$alert.show("An error occurred. Please try again.");
            }
          });
      }
    },
  },
};
